import { FC, useCallback, useState } from 'react';
import { ICustomerNote } from '../../../../models/ICustomer';
import { Box, CircularProgress, IconButton, Stack } from '@mui/material';
import ImagePreview from '../../../../ui-component/form/cb-image-upload/components/ImagePreview';
import AttachmentsGallery from '../../../../ui-component/AttachmentsGallery';

import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import useCustomerNoteActions from '../hooks/use-customer-note-actions';

import moment from 'moment-timezone';

type CustomerNoteItemProps = {
    customerId: number | string;
    note: ICustomerNote;
    onEdit: (note: ICustomerNote) => void;
};

const CustomerNoteItem: FC<CustomerNoteItemProps> = ({ note, customerId, onEdit }) => {
    const [open, setOpen] = useState(false);
    const [imgIndex, setImgIndex] = useState(-1);

    const { onDeleteNote, isDeleting, toggleNotePin, isUpdating } = useCustomerNoteActions(customerId);

    const handleClickOnImage = useCallback((index: number) => {
        setImgIndex(index);
        setOpen(true);
    }, []);

    return (
        <Box sx={{ borderRadius: '16px', border: '1px solid', borderColor: 'grey.200', p: 1.5 }}>
            <Stack spacing={1.5}>
                <Stack direction="row" sx={{ color: 'grey.200' }}>
                    <IconButton
                        color={note.is_pinned ? 'primary' : 'inherit'}
                        size="small"
                        onClick={() => toggleNotePin(note)}
                        disabled={isUpdating}
                    >
                        {isUpdating ? <CircularProgress size="24px" color="inherit" /> : <PushPinOutlinedIcon />}
                    </IconButton>
                    <Box sx={{ color: 'grey.300', fontSize: '0.75rem', lineHeight: '1rem', alignSelf: 'center' }}>
                        Created at {moment.tz(note.created_at, moment.tz.guess()).format('MMM D, YYYY, h:mm a z')}
                    </Box>

                    <Box ml="auto" sx={{ flexShrink: 0 }}>
                        <IconButton color="primary" size="small" onClick={() => onEdit(note)}>
                            <EditOutlinedIcon />
                        </IconButton>

                        <IconButton color="error" size="small" disabled={isDeleting} onClick={() => onDeleteNote(note.id)}>
                            <DeleteOutlineOutlinedIcon />
                        </IconButton>
                    </Box>
                </Stack>
                <Box sx={{ fontSize: '1rem' }}>{note.text}</Box>
                {note?.images?.length ? (
                    <Box>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
                            {note.images.map((image, index) => (
                                <ImagePreview url={image.url_small} key={image.id} onPreview={() => handleClickOnImage(index)} />
                            ))}
                        </Box>

                        <AttachmentsGallery
                            attachments={note.images}
                            isOpen={open}
                            onClose={() => setOpen(false)}
                            currImg={imgIndex}
                            setCurrImg={setImgIndex}
                            disableDelete
                        />
                    </Box>
                ) : null}
            </Stack>
        </Box>
    );
};

export default CustomerNoteItem;
